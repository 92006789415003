


































































































import Vue from 'vue'
import { Prop, Component, Mixins, Watch } from 'vue-property-decorator'
import moment from 'moment'
import dropin, { PaymentMethodPayload } from 'braintree-web-drop-in'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { Dropdowns } from '~/mixins/dropdowns'

import { UserStore } from '@/store/user'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { fetchAllBeatsLightWithPic } from '#/api/beat'
import { fetchPromoAvailability, fetchActivePromos } from '#/api/promotion'
import { fetchClientToken } from '~/api/payment'
import { initPromoSession, initPromoBraintree } from '#/api/payment'

@Component({ components: { VLoadSpinner } })
export default class PromotionPage extends Mixins(Dropdowns) {
    isLoading = true
    activePromos: IPromotion[] = []
    beats: IBeatLight[] = []
    beatSearchInput = ''
    promotionPlacement: IPromotion['placement'] = 'Trending'
    promotionBeat: IPromotion['beat'] = null
    promotionDates: Date[] = []
    availableDates: ICalendarDate[] = []
    dropinInstance: dropin.Dropin
    paymentError: object | string = null
    paymentErrorMissingBeat = false

    @UserStore.State('authenticatedUser') authenticatedUser: any

    get filteredBeats() {
        if (!this.beats) return null
        return this.beats.filter(element => element.name.match(new RegExp(this.beatSearchInput, 'i'))).sort((a, b) => a.name.localeCompare(b.name))
    }

    get paymentErrorShort() {
        return this.paymentError instanceof String ? this.paymentError.substring(0, 300) : this.paymentError
    }

    get orderTotal() {
        const priceItem = this.promotionPlacement === 'Trending' ? 20 : 10
        const priceOrderTotal = priceItem * this.promotionDates.length
        return priceOrderTotal
    }

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    updatePromotedBeat(beat: IBeatLight) {
        this.promotionBeat = beat
        this.closeMenuDropdown()
    }

    async makePayment() {
        this.paymentErrorMissingBeat = false
        this.paymentError = ''

        if (!this.promotionBeat) {
            this.paymentError = 'Error: Please select a beat to promote first'
            this.paymentErrorMissingBeat = true
            return
        }

        if (this.promotionDates.length === 0) {
            this.paymentError = 'Error: Please select the days for the promotion'
            return
        }

        try {
            // @ts-ignore
            const payload: PaymentMethodPayload = await this.dropinInstance.requestPaymentMethod({
                // @ts-ignore
                threeDSecure: { amount: this.orderTotal, email: this.authenticatedUser.email }
            })
            this.isLoading = true
            const orderId = await initPromoSession({
                payment_platform: 'Braintree',
                cart: this.promotionDates.map(it => ({
                    placement: this.promotionPlacement,
                    promoted_beat: this.promotionBeat.id,
                    day: moment(it).format('YYYY-MM-DD')
                }))
            })
            await initPromoBraintree(orderId, { nonce_from_the_client: payload.nonce })
            this.paymentError = null
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
        } catch (err: any) {
            this.paymentError = err
            console.error(err)
        } finally {
            await this._fetchActivePromos()
            await this._fetchPromoAvailability()
            await this.initBraintree()
            this.promotionBeat = null
            this.promotionPlacement = 'Trending'
            this.promotionDates = []
            this.isLoading = false
        }
    }

    async initBraintree() {
        const clientToken = await fetchClientToken()

        dropin.create(
            {
                authorization: clientToken,
                container: '#dropin-container',
                // @ts-ignore
                threeDSecure: true,
                paypal: { flow: 'vault' }
            },
            (err: object, instance: dropin.Dropin) => {
                if (err) {
                    this.paymentError = err
                    console.error(err)
                    return
                }
                this.dropinInstance = instance
            }
        )
    }

    async _fetchAllBeatsLights() {
        this.isLoading = true
        try {
            this.beats = await fetchAllBeatsLightWithPic()
        } finally {
            this.isLoading = false
        }
    }

    async _fetchPromoAvailability() {
        const dates = await fetchPromoAvailability(this.promotionPlacement)
        this.availableDates = dates.map(it => {
            const momentDate = moment(it).toDate()
            return { start: momentDate, end: momentDate }
        })
    }

    async _fetchActivePromos() {
        this.activePromos = await fetchActivePromos()
    }

    @Watch('promotionPlacement')
    onPropertyChanged(value: string, oldValue: string) {
        this.promotionDates = []
        this._fetchPromoAvailability()
    }

    created() {
        this._fetchActivePromos()
        this._fetchAllBeatsLights()
        this._fetchPromoAvailability()
        this.initBraintree()
    }
}
