import { get } from '~/services/http-requests'

const _BS_PROMO_AVAILABILITY = 'beat_promotion_avaibility_by_day'
const _BS_PROMOTED_BEATS = 'promoted_beats'

/*
 **** GET ****
 */
function fetchPromoAvailability(placement: IPromotion['placement']): Promise<string[]> {
    return get({ baseProperty: _BS_PROMO_AVAILABILITY, fullUrl: `${_BS_PROMO_AVAILABILITY}/${placement}/` })
}

function fetchActivePromos(): Promise<IPromotion[]> {
    return get({ baseProperty: _BS_PROMOTED_BEATS })
}

export { fetchPromoAvailability, fetchActivePromos }
